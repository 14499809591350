<script>
	import Session from "./classes/Session.js";


	import { string_01N } from "./utils/strings.js";

	export let cycle = {};
	export let service = {};

	let servicetype = service.type;
	let trainerid = service.trainerid;
	let rate = service.rate;
	let quantity = service.quantity;
	let unit = service.unit;
	let audienceid = service.audienceid ?? 0;

	let quantityperstudent = 0;
	let alreadygiven = '';

	const audiences0 = [
		{ id: 0, label: "Entire Group" },
		{ id: -1, label: "Per Individual" },
		{ id: -99, label: "---" },
	];

	let audiences = [];

	const servicetypes = [
		{ id: 11, code: "TRAIN", description: "Training" },
		{ id: 1, code: "LANGT", description: "-Language Training (...)" },
		{ id: 2, code: "COMMT", description: "-Communication Training (...)" },
		{ id: 3, code: "DEVEL", description: "Development" },
		{ id: 4, code: "FOLLO", description: "Follow-up" },
		{ id: 5, code: "TRANS", description: "Translation" },
		{ id: 6, code: "TESTI", description: "Testings" },
		{ id: 7, code: "ETOLO", description: "e-TOLO" },
		{ id: 9, code: "SALES", description: "Sales" },
		{ id: 13, code: "STAFF", description: "Staff" },
		{ id: 12, code: "MARKT", description: "Marketing" },
		//{ id: 10, code: "Tp", description: "Transport costs" },
		{ id: 14, code: "PERDI", description: "Per Diem" },
		{ id: 8, code: "OTHER", description: "Other" },
	];

	const units = [
		{ code: 8, label: "Session" },
		{ code: 24, label: "Hour" },
		{ code: 5, label: "Day" },
		{ code: 1, label: "Flat fee" },
	];

	/**
	 * 
	 * @param timeString HH:mm:ss
	 */
	function convertHHmmssToSeconds(timeString) {
		const [hours, minutes, seconds] = timeString.split(":").map(Number);

		return hours * 3600 + minutes * 60 + seconds;	
	}

	const createServicesForTrainees = () => {
		let newServices = [];
		cycle.students.forEach((s) => {
			let newService = {
				id: 'new' + Math.random(),
				type: servicetype,
				audienceid: s.id, // StudentInCycle.id
				quantity: quantityperstudent,
				comments: "",
				cycleid: cycle.id,
				trainerid,
				unit, 
			};
			newServices.push(newService);
		});
		cycle.services = [...cycle.services, ...newServices];

		// remove the requesting service which states 'per individual'
		cycle.services = cycle.services.filter((s0) => s0.id != service.id);

		cycle = cycle;
	};

	$: {
		quantityperstudent = Number(quantity / cycle.students.length);
	}
	$:{
		audiences = [...audiences0, ...cycle.students];
	}

	$: {
		if (cycle.trainers.length == 1) {
			trainerid = cycle.trainers[0].id;
		}
	}
	$: {
		service.type = servicetype;
		service.trainerid = trainerid;
		if (!rate && trainerid) {
			rate = cycle.trainers.find((t) => t.id == trainerid)?.trainerFromDB
				?.Tarif1;
		}
		service.rate = rate;
		service.quantity = quantity;
		service.unit = unit;
		service.audienceid = audienceid;
		if(!service?.sessions) service.sessions = [];
		service.givenquantity = service.sessions.reduce(
			(acc, s) => acc + s.Duree,
			0
		);
		service = service;
	}

	$: {
		if(service.sessions.length > 0)
		{
			// if unit is 'session', then quantity is the number of sessions
			if(service.unit == 8) {
				alreadygiven = string_01N(service.sessions.length, "No Session", "One Session", `${service.sessions.length} Sessions`);
			}
			// else count the number of units
			else
			{
				const accumulate = (acc, onesession) => {

					let onesession_o = Object.assign(new Session(), onesession);
					
					// if course was not given, do not count
					if(onesession_o.doesNotCountAsGiven()) return acc;

					let quantityhere = 0;

					if(onesession?.Duree) quantityhere = convertHHmmssToSeconds(onesession.Duree);
					if(onesession?.quantity) quantityhere = onesession.quantity;

					return acc + quantityhere;
				}
				let given = service.sessions.reduce(accumulate, 0) ?? 0;

				const unitname = units.find(u => u.code == service.unit)?.label ?? '¿durationunit?';

				if(service.unit == 24) {
					// convert seconds to hours
					given = given / 3600;
				}

				alreadygiven = string_01N(given, `No ${unitname}`, `One ${unitname}s`, `${given} ${unitname}s`);
			}
			
		}
		else
		{
			alreadygiven = '';
		}
	}
</script>

<tr>
	<td>
		<input
			name="cycleserviceid[{service.id}]"
			value={service.id}
			type="hidden"
		/>
		<!-- input name="description[{service.id}]" value="" placeholder=""><br / -->

		<select
			name="type[{service.id}]"
			bind:value={servicetype}
			class="w10em"
		>
			{#each [{ id: 0, description: "-Choose Service Type..." }, ...servicetypes] as stype}
				<option
					value={stype.id}
					disabled={stype.description.startsWith("-")}
					>{stype.description}</option
				>
			{/each}
		</select>
		<br />

		<select
			name="audienceid[{service.id}]"
			bind:value={audienceid}
			class="w10em"
		>
			{#each audiences as option}
				<option
					value={option.id}
					disabled={option?.label && option.label.startsWith("-")}
					>{option?.label ?? option.personne.surnom}</option
				>
			{/each}
		</select>
		<br />
		{service.description ?? ''}
	</td>

	<td>
		<select name="unit[{service.id}]" bind:value={unit} class="w5em">
			{#each units as oneunit}
				<option value={oneunit.code}>{oneunit.label}</option>
			{/each}
		</select>
		<br />
	</td>

	<td>
		<input
			name="quantity[{service.id}]"
			bind:value={quantity}
			placeholder=""
			class="w5em"
		/><br />
		{#if audienceid === -1}
			{quantityperstudent} per Trainee
		{/if}
	</td>

	<td>
		<input
			name="rate[{service.id}]"
			bind:value={rate}
			placeholder=""
			class="w5em"
		/><br />
	</td>

	<td>
		<select
			name="trainerid[{service.id}]"
			class="w10em"
			bind:value={trainerid}
		>
			<option value="-">Choose Trainer...</option>
			{#each cycle.trainers as trainer}
				<option
					value={trainer.id}
					xselected={trainer.id == service.trainerid}
					>{trainer.personne.surnom}</option
				>
			{/each}
		</select>

		{#if service.trainerid}
		<a
			href="/v2016/?voir=one-trainer-in-cycle&id={cycle?.trainersInCycle.find((tic) => tic.FormateurID == service.trainerid).id}"
			>→</a
		>
		{/if}
	</td>

	<td>
		{#if service.sessions.length > 0}
			{ alreadygiven } given, cannot delete
		{:else}
			<input
				type="submit"
				value="Delete"
				name="deleteservice[{service.id}]"
			/>
		{/if}

		<br />

		{#if audienceid === -1}
			<button on:click|preventDefault={ () => createServicesForTrainees() }>Create for {cycle.students.length} Trainees</button>
		{:else if service.id && typeof service.id == "number"}
			<a href="/v2016/?voir=one-cycleservice&amp;id={service.id}"
				>Edit Service details...</a
			>
		{:else}
			Save Services to edit Details
		{/if}

	</td>
</tr>
