import CycleService from './CycleService';
import Duration from './Duration';
import {getUserroles} from '../utils/user.js';

const STATUS_SCHEDULED            = 0;
const STATUS_GIVEN                = 2;
const STATUS_CANCELLEDINTIME      = 3;
const STATUS_CANCELLEDHALF        = 4;
const STATUS_CANCELLEDLATE        = 5;
const STATUS_CANCELLEDONSITE      = 6;
const STATUS_PERIODSUMMARY        = 7;
const STATUS_NEVERGIVEN           = 8;
const STATUS_MISTAKENLYGIVEN      = 9;


class Session {

	/** @type {Number} */
	id = null;

	/** @type {Number | null} */
	cycleserviceid = null;
	CycleService = null;
	/** @type {Number}  */
	CycleID = null;
	/** @type {Number} */
	formateur = null;
	quantity = null;
	Duree = '00:00:00';
	Date = '';
	/** @type {Number} Statut */
	Statut = STATUS_SCHEDULED;
	
	/** @type {Boolean} isNew */
	isNew = false;



	getSessionStatusesArray() {

		let completearray_a = [
			{ key: STATUS_SCHEDULED, value: "Scheduled"},
			{ key: STATUS_GIVEN, value: "Given"},
			{ key: STATUS_CANCELLEDINTIME, value: "Cancelled in time"},
			{ key: STATUS_CANCELLEDHALF, value: "Cancelled 50%"},
			{ key: STATUS_CANCELLEDLATE, value: "Cancelled late"},
			{ key: STATUS_CANCELLEDONSITE, value: "Cancelled on site"},
			{ key: STATUS_PERIODSUMMARY, value: "Period summary"},
			{ key: STATUS_NEVERGIVEN, value: "Never Given"},
			{ key: STATUS_MISTAKENLYGIVEN, value: "Mistakenly Given"},
		];

		// remove "scheduled" for everyone
		completearray_a = completearray_a.filter( (status) => status.key != STATUS_SCHEDULED);

		// remove "never given" for people without the specified roles
		const roles_a = ['respeda', 'sales', 'poc', 'adminlight', 'superadmin'];
		const userroles_a = getUserroles();
		const hasrole_b = roles_a.some( (role) => userroles_a.includes(role) );
		if(!hasrole_b)
		{
			completearray_a = completearray_a.filter( (status) => status.key != STATUS_NEVERGIVEN);
		}

		return completearray_a;
	}

	getCycleService() {
		return this?.CycleService ?? null;
	}

	getUnit() {
		const cs = this.getCycleService();

		if(!cs || !cs.unit)
		{
			// by default use hours
			return CycleService.UNIT_HOURS;
		}
		
		return cs.unit;
	}

	getDuration() {
		const d = new Duration();

		const unit_i = this.getUnit();

		switch (unit_i) {

			case CycleService.UNIT_HOURS:
				if(this.quantity > 0)
				{
					d.addSeconds(this.quantity);
				}
				else
				{
					d.createFromCoursDuree(this.Duree);
				}
				break;
			case CycleService.UNIT_FLATFEE:
				d.addFlatfees(this.quantity);
				break;
			case CycleService.UNIT_SESSION:
				d.addSessions(this.quantity);
				break;
			case CycleService.UNIT_DAY:
				d.addDays(this.quantity);
				break;
			default:
				console.log("No unit found for Duration");
		}

		if ( !this.quantity && unit_i != CycleService.UNIT_HOURS && this.Duree != '00:00:00' && this.Duree != '' && !this.Duree) {
			// no quantity for unit but some time is present so use it
			// suspiscious though
			d.createFromCoursDuree(this.Duree);
			console.log(`Cours ${this.id} has no quantity but has time ${this.Duree}, although uses unit ${unit_i}`);
		}

		return d;
	}

	isCancelled() {

		const is = [STATUS_CANCELLEDLATE, STATUS_CANCELLEDONSITE, STATUS_CANCELLEDHALF, STATUS_CANCELLEDINTIME].includes(this?.Statut);

		return is;
	}

	doesNotCountAsGiven()
	{
		// never given does not apply here because the time is CONSIDERED as given
		// although it does not appear in presences
		return [STATUS_CANCELLEDINTIME, STATUS_MISTAKENLYGIVEN].includes(this.Statut);
	}
}



export default Session;