
class Duration {
	constructor() {
		this.seconds = null; // internal seconds
		this.s = null; // seconds
		this.m = null; // minutes
		this.h = null; // hours
		this.n = null; // sessions
		this.d = null; // days
		this.f = null; // flatfees
	}

	totalReset() {
		// Called before each creation to reset the number of seconds
		this.seconds = null;
		this.s = null;
		this.m = null;
		this.h = null;
		this.n = null;
		this.d = null;
		this.f = null;
		return this; // for method chaining
	}

	addDuration(duration) {
		// Adds time to a Columbus time
		if (duration instanceof Duration) {
			if (duration.seconds !== null) this.addSeconds(duration.seconds);
			if (duration.n !== null) this.addSessions(duration.n);
			if (duration.d !== null) this.addDays(duration.d);
			if (duration.f !== null) this.addFlatfees(duration.f);
		} else {
			// Consider that we are adding seconds
			this.addSeconds(parseInt(duration));
		}

		return this;
	}

	/**
	 * add a number of seconds to the duration
	 *
	 * @param   {Number}  second  [second description]
	 *
	 * @return  {this}          [return description]
	 */
	addSeconds(second) {
		if (this.seconds === null) this.seconds = 0;
		this.seconds += second;
		return this;
	}

	addMinutes(minute) {
		this.addSeconds(minute * 60);
		return this;
	}

	addHours(hour) {
		this.addMinutes(hour * 60);
		return this;
	}

	addSessions(sessions) {
		if (this.n === null) this.n = 0;
		this.n += Number(sessions);
		return this;
	}

	addDays(days) {
		if (this.d === null) this.d = 0;
		this.d += Number(days);
		return this;
	}

	addFlatfees(ff) {
		if (this.f === null) this.f = 0;
		this.f += Number(ff);
		return this;
	}

	subDuration(duration) {
		// Removes time from a Columbus time
		if (duration instanceof Duration) {
			if (duration.seconds !== null) this.seconds -= duration.seconds;
			if (duration.n !== null) this.n -= duration.n;
			if (duration.d !== null) this.d -= duration.d;
			if (duration.f !== null) this.f -= duration.f;
		} else {
			// Consider that we are subtracting seconds
			this.addSeconds(- parseInt(duration));
		}

		return this;
	}

	asDecimalHours() {
		const gs = this.asSeconds();
		return gs / 3600;
	}

	asSeconds() {
		let gs = this.seconds;
		gs += this.n * 3600 * 3; // one session = 3 hours, roughly
		gs += this.d * 3600 * 8; // one day = 8 hours, roughly
		gs += this.f * 1800;
		return gs;
	}

	format(format = '%hh%m') {
		// Convert seconds to hours and minutes
		let secondsToConsume = this.seconds;

		const debug = false;
		if (debug) console.log(`seconds to use = ${secondsToConsume}`);

		// Remove hours
		this.h = Math.floor(this.seconds / 3600);
		secondsToConsume -= this.h * 3600;

		if (debug) console.log(`seconds to use = ${secondsToConsume}, hours = ${this.h}`);

		this.m = Math.floor(secondsToConsume / 60);
		this.m = this.m.toString().padStart(2, '0');
		secondsToConsume -= this.m * 60;

		if (debug) console.log(`seconds to use = ${secondsToConsume}, hours = ${this.h}, min = ${this.m}`);

		this.s = secondsToConsume.toString().padStart(2, '0');


		if (debug) console.log(`seconds to use = ${secondsToConsume}, hours = ${this.h}, min = ${this.m}, sec = ${this.s}`);

		if (secondsToConsume != 0 && !format.includes('%s')) {
			if (format.includes(':')) {
				format += ':%s'
			}
			else {
				format += '+%ss'
			}
		}

		let result = format.replace('%h', this.h.toString()).replace('%m', this.m.toString()).replace('%s', this.s.toString());

		return result;
	}

	toJSON() {
		const o = {};
		if (this.s !== null) o.s = this.s;
		if (this.m !== null) o.m = this.m;
		if (this.h !== null) o.h = this.h;
		if (this.n !== null) o.n = this.n;
		if (this.d !== null) o.d = this.d;
		o.seconds = this.seconds;
		return o;
	}

	toString() {
		const parts = [];

		if (this.f !== null) {
			parts.push(this.f + (this.f < 2 ? ' flat fee' : ' flat fees'));
		}

		if (this.n !== null) {
			parts.push(this.n + (this.n < 2 ? ' session' : ' sessions'));
		}

		if (this.d !== null) {
			parts.push(this.d + (this.d < 2 ? ' day' : ' days'));
		}

		if (this.seconds !== null) {
			parts.push(this.format('%hh%m'));
		}

		let string = parts.join(' + ');

		if (string === '') string = '-';

		return string;
	}

	createFromDecimalHours(t) {
		this.totalReset();
		const ct = new Duration();
		// Minutes are in decimal hours
		this.addHours(parseFloat(t));
		return this;
	}

	createFromCycleDuree(t) {
		return this.createFromDecimalHours(t);
	}

	createFromCoursDuree(t = '') {
		this.totalReset();

		if (!t) return this;
		t = t.trim();

		const found = t.match(/(\d+):(\d+):(\d+)/);
		if (found !== null) {
			// Add times
			this.addHours(parseInt(found[1]));
			this.addMinutes(parseInt(found[2]));
			this.addSeconds(parseInt(found[3]));
		}
		// Return the created object
		return this;
	}

	createFromFrenchTime(t = '') {
		this.totalReset();

		if (!t) return this;
		t = t.trim();

		// Sometimes we just receive "10h"
		if (t.length <= 3 && t.includes('h')) {
			t = t.replace('h', ':00');
		}
		// Sometimes we just receive "10" meaning 10 hours
		if (t.length < 3) {
			t += ':00';
		}

		const found = t.match(/(\d+)[h:.](\d\d)/);
		if (found !== null) {
			// Add times
			this.addHours(parseInt(found[1]));
			this.addMinutes(parseInt(found[2]));
		}
		// Return the created object
		return this;
	}

	/**
	 * [createFromSeconds description]
	 *
	 * @param   {Number}  s  seconds to add
	 *
	 * @return  {this}     [return description]
	 */
	createFromSeconds(s = 0) {
		this.totalReset();

		// Add times
		this.addSeconds(s);
		// Return the created object
		return this;
	}

	/**
	 * [createFromInputAndUnit description]
	 *
	 * @param   {string}  input  [input description]
	 * @param   {string}  unit   [unit description]
	 *
	 * @return  {this}         [return description]
	 */
	createFromInputAndUnit(input = '', unit = '') {

		let quantity = parseInt(input);
		let unit_s = String(unit);

		if (unit_s == '1' || unit_s == 'flatfee' || unit_s == 'flatfees') unit_s = 'f';
		if (unit_s == '5' || unit_s == 'day' || unit_s == 'days') unit_s = 'd';
		if (unit_s == '8' || unit_s == 'session' || unit_s == 'sessions') unit_s = 'n';
		if (unit_s == '24' || unit_s == 'hour' || unit_s == 'hours') unit_s = 'h';
		
		this[unit_s] = quantity;

		return this;
	}
}

export default Duration;
