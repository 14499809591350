

const getUserroles = function() {
	return document.querySelector('body').getAttribute('data-userroles').split(' ');
};

export { getUserroles };



