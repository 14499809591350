import Duration from './Duration';
import {getObject} from '../utils/api-helper';

class CycleService {

	id = null;
	unit = 0;
	cycle = null;
	quantity = 0;

	static UNIT_DEFAULT = 0;
	static UNIT_FLATFEE = 1;
	static UNIT_DAY = 5;
	static UNIT_SESSION = 8;
	static UNIT_HOURS = 24;

	/** @return {number} */
	getUnitAsInt() {
		return this.unit;
	}

	getUnitAsString() {

		let s = '—';

		switch (this.unit) {
			case CycleService.UNIT_FLATFEE:
				s = 'flat fees';
				break;
			case CycleService.UNIT_DAY:
				s = 'days';
				break;
			case CycleService.UNIT_SESSION:
				s = 'sessions';
				break;
			case CycleService.UNIT_HOURS:
				s = 'hours';
				break;

			case CycleService.UNIT_DEFAULT:
			default:
				s = '??';
		}

		return s;
	}

	getDuration() {
		const d = new Duration();
		d.createFromInputAndUnit( String(this.quantity), this.unit);
		return d;
	}

	getRemainingDuration() {

		// start with our quantity and remove time given in courses
		let remaining = this.quantity;
		let given = 0;

		remaining = this.cycle.courses.reduce((r, course) => {
			if(course.cycleserviceid != this.id) return r;
			r -= course?.quantity || 0;
			given += course?.quantity || 0;

			return r;
		}, remaining);

		return `Remaining duration = ${remaining}, ${given} given out of ${this.quantity}`;
	}

	async getRate() {
		const graph = '{rate}';
		let x = await getObject("CycleService", this.id, graph);

		console.log(x);

		return '';
	}

}

export default CycleService;